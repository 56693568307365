import React from "react";
import "./LoaderLogo.css";

class LogoLoader extends React.Component {
  render() {
    // const theme = this.props.theme;
    return <></>;
  }
}

export default LogoLoader;
